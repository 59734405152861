import { Stack, Text, useColorMode } from '@chakra-ui/react';
import { i_text_copy } from '../../../../../style';
import { getColorThemeSelector } from '../../../../../utils/funcs';
export enum LPStatusDayEnum {
    day3 = '3days',
    day5 = '5days',
}
export enum LPStatusValueEnum {
    k500 = '0.5k-5kUSD',
    k5000 = '5k+USD',
}
type LPStatusProps = {
    value: LPStatusDayEnum | LPStatusValueEnum;
    isLight: boolean;
};
const LPStatus: React.FC<LPStatusProps> = (props) => {
    const { value, isLight } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <Stack
            bg={isLight ? colorTheme('#DAE0FF', 'rgba(66, 96, 255, 0.38)') : 'inherit'}
            px={{ base: '5px', sm: '10px' }}
            py="1px"
            borderRightRadius="4px"
            border={isLight ? '' : colorTheme('1px solid #D9D8FA', '1px solid rgba(217, 216, 250, 0.25)')}
        >
            <Text
                className={i_text_copy}
                fontWeight={isLight ? '600' : '500'}
                color={isLight ? colorTheme('#0A3EF5', 'rgba(221, 224, 255, 0.83)') : colorTheme('#BDBCEA', 'rgba(189, 188, 234, 0.48)')}
            >
                {value}
            </Text>
        </Stack>
    );
};
export default LPStatus;

export enum ChainId {
    None = -1,
    // mainnet
    EthereumMainnet = 1,
    Optimism = 10,
    ZkSyncEra = 324,
    BSC = 56,
    ETC = 61,
    Aurora = 1313161554,
    Gatechain = 86,
    Heco = 128,
    Matic = 137,
    Fantom = 250,
    Arbitrum = 42161,
    Harmony = 1666600000,
    Cronos = 25,
    Icplaza = 9000,
    ConfluxESpace = 1030,
    Meter = 82,
    Telos = 40,

    // testnet
    Rinkeby = 4,
    Goerli = 5,
    BSCTestnet = 97,
    MaticTestnet = 80001,
    AuroraTestnet = 1313161555,
    HarmonyTestnet = 1666700000,
    ZkSyncAlphaTest = 280,
    MantleTest = 5001,
    ScrollTestL2 = 534354,
}

export enum TokenSymbol {
    USDC = 'USDC',
    ACY = 'ACY',
    ETH = 'ETH',
    WETH = 'WETH',
    USDT = 'USDT',
    DAI = 'DAI',
    BIT = 'BIT',
    IZI = 'iZi',
    YIN = 'YIN',
    DEVT = 'DEVT',
    DDAO = 'DDAO',
    BNB = 'BNB',
    WBNB = 'WBNB',
    IUSD = 'iUSD',
    APEX = 'APEX',
    BUSD = 'BUSD',
    ESAPEX = 'esAPEX',
    IONS = 'IONS',
    MATIC = 'MATIC',
    WMATIC = 'WMATIC',
    swETH = 'swETH',
    SWELL = 'SWELL',
    SLD = 'SLD',
    DUET = 'DUET',
    dWTI = 'dWTI',
    DUSD = 'DUSD',
    USDT18 = 'USDT18',
    KSW = 'KSW',
    REVO = 'REVO',
    LAND = 'LAND',
    FROYO = 'FROYO',
    NAS = 'NAS',
    MNW = 'MNW',
    ARC = 'ARC',
    AURORA = 'AURORA',
    TAP = 'TAP',
    ETC = 'ETC',
    WETC = 'WETC',
    MELT = 'MELT',
    DMT = 'DMT',
    BURGER = 'BURGER',
    GE = 'GE',
    RIV = 'RIV',
    BNBX = 'BNBX',
    SD = 'SD',
    CRO = 'CRO',
    USN = 'USN',
    COCA = 'COCA',
    ANT = 'ANT',
    FEVR = 'FEVR',
    LING = 'LING',

    aBNBc = 'aBNBc',
    ANKR = 'ANKR',
    ankrBNB = 'ankrBNB',
    GRI = 'GRI',
    ZBC = 'ZBC',
    ERA = 'ERA',
    GOT = 'GOT',
    WOO = 'WOO',

    HYT = 'HYT',
    MIT = 'MIT',

    slUSDT = 'slUSDT',
    slUSDC = 'slUSDC',
    slBTC = 'slBTC',
    slETH = 'slETH',

    TKO = 'TKO',
    ICT = 'ICT',
    CFX = 'CFX',
    MTR = 'MTR',
    TLOS = 'TLOS',
    WICT = 'WICT',

    WBIT = 'WBIT', // wrapper for BIT
    WCFX = 'WCFX', // wrapper for cfx
    WMTR = 'WMTR',
    MTRG = 'MTRG',
    WTLOS = 'WTLOS',

    WETH9 = 'WETH9',

    DEXTF = 'DEXTF',
    ZKSP = 'ZKSP',
    RF = 'RF',
    OT = 'OT',
    SPACE = 'SPACE',
    XSPACE = 'xSPACE',
    BLADE = 'BLADE',
    LSD = 'LSD',
    GGG = 'GGG',
    KANA = 'KANA',
    zkUSD = 'zkUSD',
    PIKO = 'PIKO',
    esPIKO = 'esPIKO',
    BEL = 'BEL',

    // test, place following tokens at the last
    FeeA = 'FeeA',
    FeeB = 'FeeB',
    TestU = 'TestU',
}

export enum FarmFixRangeContractVersion {
    V2 = 'V2',
    VEIZI = 'VEIZI',
}

export enum FarmOneSideContractVersion {
    V2 = 'V2',
    V3 = 'V3',
    VEIZI = 'VEIZI',
}

export enum FarmDynamicRangeContractVersion {
    V2 = 'V2',
    VEIZI = 'VEIZI',
}

export enum FarmOneSideiZiContractVersion {
    V1 = 'V1',
    VEIZI = 'VEIZI',
}

export enum FarmFixRangeiZiContractVersion {
    V1 = 'V1',
    VEIZI = 'VEIZI',
}

export enum FarmDynamicRangeiZiContractVersion {
    V1 = 'V1',
    VEIZI = 'VEIZI',
}

export enum ListSortByEnum {
    Default = 'Default',
    TimeAsc = 'Time Asc',
    TimeDesc = 'Time Desc',
    Liquidity = 'Liquidity',
}

declare global {
    type SupportedChainId = ChainId;
    type SupportedTokenSymbol = TokenSymbol;
}

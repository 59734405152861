import React, { useState } from 'react';
import { Flex, Box, useColorMode, Image, Text } from '@chakra-ui/react';
import Navbar from '../Navbar/Navbar';
import MediaList from '../MediaList/MediaList';
import CopyrightInfo from '../../../iZUMi-UI-toolkit/src/components/Sidebar/CopyrightInfo/CopyrightInfo';
import ToggleIcon from '../../../iZUMi-UI-toolkit/src/components/Sidebar/ToggleIcon/ToggleIcon';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useHistory } from 'react-router-dom';
import useIsMobile from '../../../hooks/useIsMobile';
import './Sidebar.css';
import { formatNumber } from '../../../utils/tokenMath';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../state/store';
import { ChainId, TokenSymbol } from '../../../types/mod';
import { links } from '../../../config/links';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';

import LanguageSelect from '../LanguageSelect/LanguageSelect';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { useHover } from '../../../hooks/useHover';
import { i_h4 } from '../../../style';
import BuyiZiButton from '../../BuyiZiButton/BuyiZiButton';
import ColorModeSelect from '../../ColorModeSelect/ColorModeSelect';
import { getSafeTokenPrice } from '../../../state/models/token/funcs';

const Sidebar: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const bgColor = colorTheme('#ffffff', '#292343');
    const [showNav, setShowNav] = useState(false);
    const history = useHistory();
    const isMobile = useIsMobile();
    const { token } = useSelector((state: RootState) => state);
    const { chainId } = useWeb3WithDefault();

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { pageLayout } = useSelector((state: RootState) => state);
    const [ref, isHovering] = useHover<any>();

    return (
        <Flex
            h="100%"
            w={
                pageLayout.inAnimation
                    ? '0px'
                    : {
                          base: showNav ? '248px' : '0px',
                          lg: showNav ? '248px' : '108px',
                      }
            }
            direction="column"
            boxShadow="0 0 15px 0 rgb(0 0 0 / 3%)"
            bgColor={bgColor}
            display="flex"
            visibility={{ base: showNav ? 'visible' : 'hidden', lg: 'visible' }}
            align="flex-start"
            flexShrink={0}
            transitionProperty="all"
            transitionDuration="0.3s"
            transitionTimingFunction="ease"
            transitionDelay={showNav ? '0s' : '0.2s'}
            position={{ base: 'absolute', lg: 'relative' }}
            zIndex={{ base: '8', lg: '8' }}
        >
            <ToggleIcon
                top="97px"
                right="-18px"
                showNav={showNav}
                setShowNav={setShowNav}
                zIndex="8 !important"
                visibility={isMobile ? 'hidden' : 'visible'}
            />
            <Box flexShrink={0} w="100%" height="140px" position="relative" mb="10px !important">
                <Image
                    src={process.env.PUBLIC_URL + '/assets/home/iziLogo/logo.svg'}
                    width="38px"
                    height="38px"
                    position="absolute"
                    left="35px"
                    top="46px"
                    cursor="pointer"
                    onClick={() => {
                        history.push('/home');
                    }}
                />
                <Text
                    className={i_h4}
                    // w="84px"
                    fontSize="24px !important"
                    color={colorTheme('#1D0928', '#F5F5F5')}
                    width={showNav ? '100px' : '0px'}
                    // height={showNav ? '25px' : '0px'}
                    position="absolute"
                    left="87px"
                    top="55px"
                    opacity={showNav ? 1 : 0}
                    cursor="pointer"
                    onClick={() => {
                        history.push('/home');
                    }}
                    transitionProperty="opacity"
                    transitionDuration="0.2s"
                    transitionTimingFunction="ease"
                    transitionDelay={showNav ? '0.3s' : '0s'}
                >
                    {showNav ? 'iZiSwap' : ''}
                </Text>
            </Box>
            <Navbar setShowNav={setShowNav} showNav={showNav} />
            <Box flex={1} />

            {showNav ? (
                <>
                    <Flex w={'184px'} direction="row" align="center" mb={3} mx="auto">
                        <ColorModeSelect showNav={showNav} />
                        <LanguageSelect ml="2px" />
                        <Flex ref={ref} ml="2px">
                            <Image
                                cursor="pointer"
                                boxSize="21px"
                                src={
                                    isHovering
                                        ? colorTheme(
                                              '/assets/header/HoverchangeHeaderLayout.svg',
                                              '/assets/sidebar/HoverdarkchangeSideBarLayout.svg'
                                          )
                                        : colorTheme('/assets/header/changeHeaderLayout.svg', '/assets/header/darkchangeHeaderLayout.svg')
                                }
                                fallbackSrc="/assets/header/changeHeaderLayout.svg"
                                //isHovering={isHovering}
                                onClick={() => {
                                    setShowNav(false);
                                    dispatch.pageLayout.setIsInAnimation(true);
                                    setTimeout(() => {
                                        dispatch.pageLayout.setPageLayout('header');
                                    }, 500);
                                    setTimeout(() => {
                                        dispatch.pageLayout.setIsInAnimation(false);
                                    }, 500);
                                }}
                            ></Image>
                        </Flex>

                        <BuyiZiButton
                            expand={showNav}
                            tokenPrice={formatNumber(getSafeTokenPrice(token, TokenSymbol.IZI))}
                            onClick={() => {
                                window.open(links.iZiBuyLink[chainId] ?? links.iZiBuyLink[ChainId.EthereumMainnet]);
                            }}
                            ml="auto"
                        />
                    </Flex>

                    <MediaList />
                    <CopyrightInfo />
                </>
            ) : (
                <Flex direction="column" w="95%" align="center" mb="97px" mx="auto" px={4} justifyContent="center">
                    <ColorModeSelect showNav={showNav} w="unset" />
                    {/* <Box mt="21px"></Box>
                    {<LanguageSelect />} */}
                    <BuyiZiButton
                        mt="21px"
                        expand={showNav}
                        tokenPrice={formatNumber(getSafeTokenPrice(token, TokenSymbol.IZI))}
                        onClick={() => {
                            window.open(links.iZiBuyLink[chainId] ?? links.iZiBuyLink[ChainId.EthereumMainnet]);
                        }}
                    />
                </Flex>
            )}
        </Flex>
    );
};

export default Sidebar;

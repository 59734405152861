import React, { useState } from 'react';
import { BoxProps, ChakraProps, HStack, Image, Text, useColorMode } from '@chakra-ui/react';
import useIsMobile from '../../hooks/useIsMobile';
import CustomButton from '../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { i_text_copy_bold } from '../../style';
import { getColorThemeSelector } from '../../utils/funcs';

type BuyiZiButtonProps = {
    expand: boolean;
    tokenPrice: string;
    onClick: any;
    imageProps?: ChakraProps;
} & BoxProps;

const BuyiZiButton: React.FC<BuyiZiButtonProps> = (props) => {
    const { expand, tokenPrice, onClick, imageProps, ...rest } = props;
    const mobile = useIsMobile();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [isHovering, setIsHovering] = useState(false);
    return (
        <CustomButton
            hidden={!expand && mobile}
            variant="primary"
            w={expand ? (mobile ? '70px' : '88px') : '30px'}
            h={mobile ? '26px' : '30px'}
            borderRadius="15px"
            bg={colorTheme('#F0F0FB', '#1D2232')}
            color={colorTheme('#4F54CF', '#8C8DFC')}
            _hover={{ bg: colorTheme('#4046C3', '#4F54CF'), color: colorTheme('#FFFFFF', '#D5D5DB') }}
            onMouseOver={() => {
                setIsHovering(true);
            }}
            onMouseLeave={() => {
                setIsHovering(false);
            }}
            text={
                <HStack w="100%" mx="auto" justifyContent="center">
                    <Image
                        w={mobile ? '14px' : '19px'}
                        h={mobile ? '14px' : '19px'}
                        src={
                            isHovering
                                ? colorTheme(process.env.PUBLIC_URL + '/assets/buyizi/hoverIcon.svg', '/assets/buyizi/darkHoverIcon.svg')
                                : colorTheme(process.env.PUBLIC_URL + '/assets/buyizi/icon.svg', '/assets/buyizi/darkIcon.svg')
                        }
                        fallbackSrc={
                            isHovering
                                ? colorTheme(process.env.PUBLIC_URL + '/assets/buyizi/hoverIcon.svg', '/assets/buyizi/darkHoverIcon.svg')
                                : colorTheme(process.env.PUBLIC_URL + '/assets/buyizi/icon.svg', '/assets/buyizi/darkIcon.svg')
                        }
                        {...imageProps}
                    />
                    {expand &&
                        (tokenPrice === 'undefined' ? (
                            <Image
                                w={mobile ? '14px' : '19px'}
                                h={mobile ? '14px' : '19px'}
                                src={'/assets/sidebar/whiteLoading.gif'}
                                fallbackSrc="/assets/sidebar/whiteLoading.gif"
                            ></Image>
                        ) : (
                            <Text className={i_text_copy_bold} mx={mobile ? '3px !important' : '6px !important'}>
                                ${/* --.-- */}
                                {tokenPrice}
                            </Text>
                        ))}
                </HStack>
            }
            onClick={() => {
                onClick();
            }}
            {...rest}
        />
    );
};

export default BuyiZiButton;

import { Image, useColorMode, Text, BoxProps, VStack, Stack, Checkbox } from '@chakra-ui/react';
import { useState } from 'react';
import { links } from '../../../../../config/links';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { Modal } from '../../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { RootDispatch } from '../../../../../state/store';
import { i_h4, i_text_d, i_text_copy } from '../../../../../style';
import { getColorThemeSelector } from '../../../../../utils/funcs';

type Props = {
    isOpen: boolean;
    onClose: () => void;
} & BoxProps;
export const LegacyWarningModal: React.FC<Props> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { isOpen, onClose, ...rest } = props;
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const [isNotShowAgain, setIsNOtShowAgain] = useState(false);

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                dispatch.liquidity.setShowLegacyWarning(!isNotShowAgain);
                onClose();
            }}
            w={{ base: '341px', sm: '540px' }}
            minH={{ base: '383px', sm: '478px' }}
            title=""
            isShowClose={true}
            titleProps={{ mb: '0px', pb: '0px' }}
            {...rest}
        >
            <VStack spacing={{ base: '20px', sm: '30px' }}>
                <Image
                    w={{ base: '99px', sm: '150px' }}
                    h={{ base: '79px', sm: '120px' }}
                    src={colorTheme('/assets/swap/legacyModal/warning.svg', '/assets/swap/legacyModal/warning.svg')}
                    fallbackSrc={colorTheme('/assets/swap/legacyModal/warning.svg', '/assets/swap/legacyModal/warning.svg')}
                ></Image>
                <Text
                    className={i_h4}
                    w={{ base: '272px', sm: '326px' }}
                    mt="15px !important"
                    color={colorTheme('tertiary.800', '#FFF')}
                    textAlign="center"
                    whiteSpace="pre-wrap"
                    opacity="0.5"
                >
                    {'Important notice'}
                </Text>

                <VStack
                    w="100%"
                    h="197px"
                    bg={colorTheme('#F9F9FB', '#1b1823')}
                    p="16px"
                    spacing={{ base: '20px', sm: '30px' }}
                    borderRadius="9px"
                >
                    <Text className={i_text_d} color={colorTheme('#938CA4', '#B7B1BE')} textAlign="center">
                        New contracts have been upgraded in July, and the old contracts are no longer maintained. Please check if you have
                        old liquidity with the following link and remove them.
                    </Text>
                    <CustomButton
                        w="162px"
                        h="38px"
                        variant="purple"
                        className={i_text_copy}
                        fontWeight="700"
                        text={'Inactive Liquidity'}
                        onClick={() => {
                            window.open(links.legacyLink);
                        }}
                    ></CustomButton>
                </VStack>
                <Stack w="100%">
                    <Checkbox
                        defaultChecked={false}
                        onChange={(e) => {
                            setIsNOtShowAgain(e.target.checked);
                        }}
                    >
                        <Text className={i_text_copy} color={colorTheme('tertiary.800', '#FFF')} opacity="0.5">
                            don not show again
                        </Text>
                    </Checkbox>
                </Stack>
            </VStack>
        </Modal>
    );
};

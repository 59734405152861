import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { MdAddChart } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Box, useColorMode, Button, Icon, HStack, Text, Center, Stack } from '@chakra-ui/react';
import { Heading } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import JumpButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/JumpButton/JumpButton';
import LimitForm from './LimitForm';
import Tabs from '../components/Tabs';
import { i_h3 } from '../../../style';
import AdvanceLimit from './AdvanceLimit';
import ChainNotValidBlockWrapper from '../../components/ChainNotValidBlock';
import PageHeader from '../../../components/PageHeader/PageHeader';
import PageLayout from '../../../components/layout/PageLayout';
import { RootDispatch, RootState } from '../../../state/store';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { getColorThemeSelector } from '../../../utils/funcs';
import NetworkSelect from '../../../components/Select/NetworkSelect/NetworkSelect';
import useIsMobile from '../../../hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { WarningInfo } from '../../../components/WarningInfo/WarningInfo';

const Limit: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { tradeMode } = useSelector((state: RootState) => state);
    const location = useLocation();
    const [showWarning, setShowWarning] = useState(true);

    const isSimpleMode = tradeMode.isSimpleMode;
    const oneLineMode = useBreakpointValue({ base: false, xl: true })!;
    const setIsSimpleMode = useCallback(
        (e: boolean) => {
            dispatch.tradeMode.setIsSimpleMode(e);
        },
        [tradeMode, dispatch]
    );
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    useEffect(() => {
        const locationState = location.state as any;
        if (locationState) {
            setIsSimpleMode(false);
        }

        document.title = t('Exchange') + ' | iZiSwap';
    }, [t]);
    const tabValue = 'Limit Order';
    const handleChangeTab = (value: string) => {
        if (value === 'Swap') {
            history.push('/swap');
        }
    };
    const isMobile = useIsMobile();
    const isXXl = useBreakpointValue({ base: false, xxl: true, '2xl': true });

    return isMobile ? (
        <PageLayout
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Center pt="7px" w="100%">
                            <LimitForm type="mobile" />
                        </Center>
                    }
                    app="trade"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                isSimpleMode ? (
                    <Box h="0px"></Box>
                ) : (
                    <PageHeader
                        w={{
                            base: '700px',
                            lg: '1113px',
                            xxl: '1113px',
                            xlp1: '1150px',
                        }}
                        headerTitle={
                            <HStack spacing="20px">
                                <Heading level="3" fontSize={oneLineMode ? '24px' : '20px'}>
                                    {t('Exchange')}
                                </Heading>
                                <Box h="60%" w="3px" bg={colorTheme('tertiary.200', 'tertiary.200')} />
                                <Text
                                    className={i_h3}
                                    fontSize={oneLineMode ? '23px' : '19px'}
                                    color={colorTheme('secondary.600', 'secondary.300')}
                                >
                                    {t('Limit Order')}
                                </Text>
                            </HStack>
                        }
                        subtitle={t('Swap tokens with target price') + '.'}
                        element={
                            <HStack spacing="10px">
                                <Button
                                    w="34px"
                                    h="34px"
                                    mr="10px"
                                    bgColor={colorTheme('#EBEAEC', '#52466A')}
                                    color={colorTheme('#52466A', '#EBEAEC')}
                                    borderRadius="3px"
                                    fontSize="14px"
                                    onClick={() => setIsSimpleMode(!isSimpleMode)}
                                    p="7px"
                                >
                                    <Icon as={MdAddChart} boxSize="20px" />
                                </Button>

                                <JumpButton w="150px" h="40px" text={t('Manage Liquidity')} target="/liquidity" mr="10px" />
                                <NetworkSelect />
                            </HStack>
                        }
                    />
                )
            }
            body={
                isSimpleMode ? (
                    <ChainNotValidBlockWrapper
                        content={
                            <Center pt={{ base: '56px', sm: '29px', xxl: '56px' }} w="100%" mt="0px !important" alignItems="start">
                                <Tabs
                                    variant={'simple'}
                                    list={[
                                        {
                                            label: t('Swap'),
                                            value: 'Swap',
                                            component: <></>,
                                        },
                                        {
                                            label: t('Limit Order'),
                                            value: 'Limit Order',
                                            component: <LimitForm />,
                                        },
                                    ]}
                                    endList={[
                                        {
                                            label: t('Limit Order'),
                                            value: 'Limit Order',
                                            component: (
                                                <Button
                                                    maxW="38px !important"
                                                    h={isSimpleMode ? { base: '30px', sm: '24px', xxl: '30px' } : '30px'}
                                                    borderRadius="3px"
                                                    bgColor={colorTheme('#EBEAEC', '#52466A')}
                                                    color={colorTheme('#52466A', '#EBEAEC')}
                                                    fontSize="14px"
                                                    minW={isSimpleMode ? { base: 'unset', sm: '25px', xxl: 'unset' } : 'unset'}
                                                    px={isSimpleMode ? { base: '12px', sm: '4px', xxl: '12px' } : 'unset'}
                                                    onClick={() => setIsSimpleMode(!isSimpleMode)}
                                                    p="7px"
                                                >
                                                    <Icon as={MdAddChart} boxSize="20px" />
                                                </Button>
                                            ),
                                        },
                                        {
                                            label: t('Liquidity'),
                                            value: 'Liquidity',
                                            component: (
                                                <JumpButton
                                                    w={{ base: '100px', sm: '80px', xxl: '100px' }}
                                                    h={{ base: '30px', sm: '24px', xxl: '30px' }}
                                                    text={t('Liquidity')}
                                                    target="/liquidity"
                                                />
                                            ),
                                        },
                                        {
                                            label: t('Network'),
                                            value: 'Network',
                                            component: (
                                                <Stack bg={colorTheme('#fbfbfb', '#140E28')} color={colorTheme('#000000', '#ffffff')}>
                                                    <NetworkSelect
                                                        buttonStyle={{ h: '30px !important' }}
                                                        rightIconStyle={{ boxSize: '20px' }}
                                                        hidden={isXXl ? false : true}
                                                    />
                                                </Stack>
                                            ),
                                        },
                                    ]}
                                    value={tabValue}
                                    handleClick={handleChangeTab}
                                ></Tabs>
                            </Center>
                        }
                        app="trade"
                    />
                ) : (
                    <Stack
                        w={{
                            base: '700px',
                            lg: '1113px',
                            xxl: '1113px',
                            xlp1: '1150px',
                        }}
                    >
                        <ChainNotValidBlockWrapper
                            content={
                                <Stack>
                                    <WarningInfo isShow={showWarning} onclose={setShowWarning}></WarningInfo>
                                    <AdvanceLimit tabValue="Limit Order"></AdvanceLimit>
                                </Stack>
                            }
                            app="trade"
                        />
                    </Stack>
                )
            }
        />
    );
};

export default Limit;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdOutlineSwapVerticalCircle } from 'react-icons/md';
import { Stack, HStack, Icon } from '@chakra-ui/react';
import JumpButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/JumpButton/JumpButton';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { PositionList } from './components/PositionList';
import NotConnectModal from '../components/NotConnectModal';
import PageHeader from '../../../components/PageHeader/PageHeader';
import PageLayout from '../../../components/layout/PageLayout';
import MyLiquidityControls from './components/MyLiquidityControl';
import ChainNotValidBlockWrapper from '../../components/ChainNotValidBlock';
import NetworkSelect from '../../../components/Select/NetworkSelect/NetworkSelect';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import LiquidityWarning from './components/LiquidityWarning/LiquidityWarning';
import LegacyWarning from './components/LiquidityWarning/LegacyWarning';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { useHasLegacyLiquidity } from '../../../hooks/useHasLegacyLiquidity';

const Liquidity: React.FC = () => {
    const { t } = useTranslation();
    const { account } = useWeb3WithDefault();
    useEffect(() => {
        document.title = t('Liquidity') + ' | iZiSwap';
    }, [t]);
    const history = useHistory();
    const isMobile = useIsMobile();
    const {
        iZiSwapLiquidityList: { liquidityList: liquidityListRaw },
    } = useSelector((state: RootState) => state);
    const [fetchLoading, setFetchLoading] = useState(false);
    const { hasLegacyLiquidity } = useHasLegacyLiquidity();

    return isMobile ? (
        <PageLayout
            body={
                account ? (
                    <ChainNotValidBlockWrapper
                        content={
                            <Stack w="100%">
                                {liquidityListRaw.length === 0 && !fetchLoading && hasLegacyLiquidity && <LegacyWarning />}
                                <MyLiquidityControls />
                                <PositionList fetchLoading={fetchLoading} setFetchLoading={setFetchLoading} />
                            </Stack>
                        }
                        app="trade"
                    />
                ) : (
                    <NotConnectModal />
                )
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={t('Liquidity')}
                    subtitle={t('Provide liquidity to earn transaction fees') + '.'}
                    element={
                        <HStack spacing="10px">
                            <CustomButton
                                variant="tertiary"
                                w="34px"
                                h="34px"
                                borderRadius="3px"
                                text={<Icon as={MdOutlineSwapVerticalCircle} boxSize="28px" px="3px" />}
                                onClick={() => {
                                    history.push('/swap');
                                }}
                            />

                            <JumpButton
                                disabled={!account}
                                w="140px"
                                h="40px"
                                text={t('Add Liquidity') + ' +'}
                                target="/add-liquidity/custom"
                            />
                            <NetworkSelect />
                        </HStack>
                    }
                />
            }
            body={
                account ? (
                    <ChainNotValidBlockWrapper
                        content={
                            <Stack w="100%">
                                <LiquidityWarning />
                                {liquidityListRaw.length === 0 && !fetchLoading && hasLegacyLiquidity && <LegacyWarning />}
                                <MyLiquidityControls />
                                <PositionList fetchLoading={fetchLoading} setFetchLoading={setFetchLoading} />
                            </Stack>
                        }
                        app="trade"
                    />
                ) : (
                    <NotConnectModal />
                )
            }
            internalWidth={{ base: '700px', xl: '840px', xxl: '1100px', xlp1: '1150px' }}
        />
    );
};

export default Liquidity;

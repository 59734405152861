import { BoxProps, Divider, HStack, Stack, Text, VStack, Image, useColorMode } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { WarningInfo } from '../../../../../components/WarningInfo/WarningInfo';
import { POPULAR_LP_SELECTION, RiskEnum } from '../../../../../config/bizConfig';
import { useTokenListFormatted } from '../../../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { useCustomToast } from '../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { AprTypeEnum, getLiquidityApr, RequestLiquidityApr } from '../../../../../net/iZUMi-endpoints/src/restful/izumiLiquidity';
import {
    ResponseIziSwapPoolSummaryRecord,
    getIziSwapGenericSummaryRecord,
    SummaryRecordTypeEnum,
} from '../../../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { LiquidityApr } from '../../../../../net/iZUMi-endpoints/src/restful/izumiLiquidity';
import { getToken } from '../../../../../state/models/pro/pro';
import { i_h4, i_text_copy_bold, i_text_d } from '../../../../../style';
import { getColorThemeSelector, tocheckSumAddress, toFeeNumber } from '../../../../../utils/funcs';
import AddPopularLiquidityForm from './AddPopularLiquidityForm';
import PopularEntry from './PopularEntry';
import Step from './Step';
import { Loading, LoadingEnum } from '../../../../components/Loading';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { getBaseQuoteOrderBySymbol } from '../../../../../state/models/pro/proOrderFormState/funcs';
import { isGasOrWrappedGasToken } from '../../../../../config/tokens';
import { useGasToken } from '../../../../../state/models/hooks/useGasToken';

const deduplicateArray = (arr: any[]): any[] => {
    const map = new Map<string, any>();
    return arr.reduce((acc: any[], cur: any) => {
        if (!map.has(cur.pool_address)) {
            map.set(cur.pool_address, cur);
            return [...acc, cur];
        }
        return acc;
    }, []);
};
type LiquidityAprWithRisk = {
    risk: RiskEnum;
} & LiquidityApr;
type EntrysProps = {
    baseTokenSymbol: string;
    quoteTokenSymbol: string;
    aprs: LiquidityAprWithRisk[];
} & ResponseIziSwapPoolSummaryRecord;
const AddPopularLiquidity: React.FC<BoxProps> = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const toast = useCustomToast();
    const { tokenList } = useTokenListFormatted();
    const isMobile = useIsMobile();
    const gasToken = useGasToken();

    const [showWarning, setShowWarning] = useState(true);
    const [selectPoolIndex, setSelectPoolIndex] = useState(-1);
    const [selectIndex, setSelectIndex] = useState(-1);

    const history = useHistory();

    const [entrys, setEntrys] = useState([] as EntrysProps[]);
    const { chainId } = useWeb3WithDefault();
    const [networkLoading, setNetworkLoading] = useState(false);
    const popularPool = deduplicateArray(POPULAR_LP_SELECTION[chainId]) ?? ['0x6ac81d4c43c86c8dbd4842c1eb0fd1a1c2c16b97'];
    const isSelected = useMemo(() => {
        return selectIndex === -1 ? false : true;
    }, [selectIndex]);

    useEffect(() => {
        setNetworkLoading(true);
        const tempEntrys = [] as EntrysProps[];
        let count = 0;
        popularPool.map((item: any) => {
            getIziSwapGenericSummaryRecord<ResponseIziSwapPoolSummaryRecord[]>({
                chain_id: chainId,
                type: SummaryRecordTypeEnum.IZI_POOL_LATEST,
                contract_addr: item.pool_address,
            })
                .then((e) => {
                    if (e.data.data.length > 0) {
                        const data = e.data.data[0];
                        getLiquidityApr({ address: data.address, chainId: chainId, type: AprTypeEnum.Recommend } as RequestLiquidityApr)
                            .then((e) => {
                                const result = e.data.data as LiquidityAprWithRisk[];
                                result.map((item) => {
                                    item.risk = POPULAR_LP_SELECTION[chainId][count].risk;
                                    count++;
                                });
                                const tokenX = data.tokenX;
                                const tokenY = data.tokenY;

                                const { baseToken: baseTokenSymbol, quoteToken: quoteTokenSymbol } = getBaseQuoteOrderBySymbol(
                                    tokenX,
                                    tokenY,
                                    chainId
                                );

                                tempEntrys.push({
                                    ...data,
                                    baseTokenSymbol,
                                    quoteTokenSymbol,
                                    aprs: result,
                                    address: tocheckSumAddress(data.address),
                                });
                            })
                            .catch((e) => {
                                toast('error', e.message);
                            })
                            .finally(() => {
                                setNetworkLoading(false);
                            });
                    }
                })
                .catch((e) => {
                    toast('error', e.message);
                })
                .finally(() => {});
        });
        setEntrys(tempEntrys);
    }, [chainId]);

    return isMobile ? (
        <VStack w="100%">
            <Stack w={{ base: '100%', sm: '868px' }} mt="10px" spacing="25px">
                <WarningInfo isShow={showWarning} onclose={setShowWarning}></WarningInfo>

                <Card
                    w="100%"
                    minH="459px"
                    bg={colorTheme('#FDFEFF', '#241A38')}
                    pt="20px"
                    pb="30px"
                    px="20px"
                    mb={{ base: '60px !important', sm: 'unset' }}
                >
                    <HStack spacing="21px" justifyContent="space-between">
                        <Text className={i_h4} fontSize="16px" color={colorTheme('#3E4079', '#C5C7FF')}>
                            Popular selections
                        </Text>
                        <CustomButton
                            variant="lightPurple"
                            className={i_text_d}
                            fontSize="14px"
                            fontWeight="600"
                            color={colorTheme('#8000FF', '#ECDAFF')}
                            bg={colorTheme(
                                'linear-gradient(90.61deg, rgba(230, 220, 255, 0.71) -19.1%, #F2E2FF 160.54%, rgba(76, 70, 90, 0.38) 223.79%)',
                                'linear-gradient(90.61deg, rgba(70, 49, 121, 0.71) -19.1%, #421267 160.54%, rgba(76, 70, 90, 0.38) 223.79%)'
                            )}
                            _hover={{ opacity: 0.7 }}
                            w="118px"
                            h="36px"
                            text="Custom >>"
                            onClick={() => {
                                history.push('/add-liquidity/custom');
                            }}
                        ></CustomButton>
                    </HStack>
                    <Divider mt="10px"></Divider>

                    <Stack spacing={{ base: '18px', sm: '30px' }} mt="10px">
                        {isSelected ? (
                            <HStack h="17px">
                                <CustomButton
                                    className={i_text_copy_bold}
                                    leftIconElement={
                                        <Image w="16px" h="13px" src="/assets/liquidity/addLiquidity/back.svg" mr="10px"></Image>
                                    }
                                    color="#9B8FAE"
                                    text="BACK"
                                    onClick={() => {
                                        setSelectIndex(-1);
                                    }}
                                ></CustomButton>
                            </HStack>
                        ) : (
                            <HStack h="17px"></HStack>
                        )}
                        <VStack w="100%" justifyContent="center" mt="10px !important">
                            <Step isSelected={isSelected ? false : true} stepIndex={1} content="Select pair&range"></Step>
                            <Image
                                w="8px"
                                h="19px"
                                src={colorTheme(
                                    process.env.PUBLIC_URL + '/assets/liquidity/addLiquidity/ArrowDown.svg',
                                    '/assets/liquidity/addLiquidity/ArrowDown.svg'
                                )}
                            ></Image>
                            <Step isSelected={isSelected ? true : false} stepIndex={2} content="Enter amount"></Step>
                        </VStack>
                        {networkLoading ? (
                            <Loading variant={LoadingEnum.purple} text="Loading..." pt="100px" pb="30px" />
                        ) : isSelected ? (
                            <Stack spacing={{ base: '22px', sm: '8px' }}>
                                <Divider></Divider>

                                <AddPopularLiquidityForm
                                    tokenA={getToken(entrys[selectPoolIndex].baseTokenSymbol, chainId, tokenList)}
                                    tokenB={getToken(entrys[selectPoolIndex].quoteTokenSymbol, chainId, tokenList)}
                                    fee={toFeeNumber(entrys[selectPoolIndex].fee)}
                                    leftRange={entrys[selectPoolIndex].aprs[selectIndex].leftRange}
                                    rightRange={entrys[selectPoolIndex].aprs[selectIndex].rightRange}
                                />
                            </Stack>
                        ) : (
                            <Stack spacing="18px">
                                {entrys.map((item, index) => {
                                    let tokenA = getToken(item.baseTokenSymbol, chainId, tokenList);
                                    let tokenB = getToken(item.quoteTokenSymbol, chainId, tokenList);

                                    const tokenAIsGasToken = isGasOrWrappedGasToken(tokenA, chainId);
                                    const tokenBIsGasToken = isGasOrWrappedGasToken(tokenB, chainId);
                                    tokenA = tokenAIsGasToken ? gasToken : tokenA;
                                    tokenB = tokenBIsGasToken ? gasToken : tokenB;

                                    if (!tokenA || !tokenB) {
                                        return <></>;
                                    }
                                    return item.aprs.map((entry, i) => {
                                        const matchAddress = POPULAR_LP_SELECTION[chainId].filter((selectionItem: any) => {
                                            return tocheckSumAddress(selectionItem.pool_address) === item.address;
                                        });
                                        if (matchAddress.length !== item.aprs.length) {
                                            return <></>;
                                        }
                                        return (
                                            <Stack spacing="18px" key={i}>
                                                <Divider></Divider>
                                                <PopularEntry
                                                    index={i}
                                                    poolIndex={index}
                                                    tokenA={tokenA}
                                                    tokenB={tokenB}
                                                    fee={item.fee}
                                                    leftRange={entry.leftRange}
                                                    rightRange={entry.rightRange}
                                                    apr={entry.apr}
                                                    slideApr={entry.slideApr}
                                                    level={entry.risk}
                                                    setSelect={setSelectIndex}
                                                    setSelectPoolIndex={setSelectPoolIndex}
                                                ></PopularEntry>
                                            </Stack>
                                        );
                                    });
                                })}
                            </Stack>
                        )}
                    </Stack>
                </Card>
            </Stack>
        </VStack>
    ) : (
        <VStack w="100%">
            <Stack w={{ base: '100%', sm: '868px' }} mt="10px" spacing="25px">
                <WarningInfo isShow={showWarning} onclose={setShowWarning}></WarningInfo>

                <HStack spacing="21px">
                    <Text className={i_h4} fontSize="22px" color={colorTheme('#3E4079', '#C5C7FF')}>
                        Popular selections
                    </Text>
                    <CustomButton
                        variant="lightPurple"
                        className={i_text_d}
                        fontSize="14px"
                        fontWeight="600"
                        color={colorTheme('#8000FF', '#ECDAFF')}
                        bg={colorTheme(
                            'linear-gradient(90.61deg, rgba(230, 220, 255, 0.71) -19.1%, #F2E2FF 160.54%, rgba(76, 70, 90, 0.38) 223.79%)',
                            'linear-gradient(90.61deg, rgba(70, 49, 121, 0.71) -19.1%, #421267 160.54%, rgba(76, 70, 90, 0.38) 223.79%)'
                        )}
                        _hover={{ opacity: 0.7 }}
                        w="158px"
                        h="36px"
                        text="Custom >>"
                        onClick={() => {
                            history.push('/add-liquidity/custom');
                        }}
                    ></CustomButton>
                </HStack>
                <Card
                    w="100%"
                    minH="459px"
                    bg={colorTheme('#FDFEFF', '#241A38')}
                    pt="20px"
                    pb="30px"
                    px="40px"
                    mb={{ base: '60px !important', sm: 'unset' }}
                >
                    <Stack spacing="30px">
                        {isSelected ? (
                            <HStack h="17px">
                                <CustomButton
                                    className={i_text_copy_bold}
                                    leftIconElement={
                                        <Image w="16px" h="13px" src="/assets/liquidity/addLiquidity/back.svg" mr="10px"></Image>
                                    }
                                    color="#9B8FAE"
                                    text="BACK"
                                    onClick={() => {
                                        setSelectIndex(-1);
                                    }}
                                ></CustomButton>
                            </HStack>
                        ) : (
                            <HStack h="17px"></HStack>
                        )}

                        <HStack w="100%" justifyContent="center" mt="0px !important">
                            <Step isSelected={isSelected ? false : true} stepIndex={1} content="Select pair&range"></Step>
                            <Image
                                w="43px"
                                h="8px"
                                src={colorTheme(
                                    process.env.PUBLIC_URL + '/assets/liquidity/addLiquidity/ArrowRight.svg',
                                    '/assets/liquidity/addLiquidity/darkArrowRight.svg'
                                )}
                            ></Image>
                            <Step isSelected={isSelected ? true : false} stepIndex={2} content="Enter amount"></Step>
                        </HStack>
                        {networkLoading ? (
                            <Loading variant={LoadingEnum.purple} text="Loading..." pt="100px" pb="30px" />
                        ) : isSelected ? (
                            <Stack>
                                <Divider></Divider>

                                <AddPopularLiquidityForm
                                    tokenA={getToken(entrys[selectPoolIndex].baseTokenSymbol, chainId, tokenList)}
                                    tokenB={getToken(entrys[selectPoolIndex].quoteTokenSymbol, chainId, tokenList)}
                                    fee={toFeeNumber(entrys[selectPoolIndex].fee)}
                                    leftRange={entrys[selectPoolIndex].aprs[selectIndex].leftRange}
                                    rightRange={entrys[selectPoolIndex].aprs[selectIndex].rightRange}
                                />
                            </Stack>
                        ) : (
                            <Stack spacing="20px">
                                {entrys.map((item, index) => {
                                    let tokenA = getToken(item.baseTokenSymbol, chainId, tokenList);
                                    let tokenB = getToken(item.quoteTokenSymbol, chainId, tokenList);

                                    const tokenAIsGasToken = isGasOrWrappedGasToken(tokenA, chainId);
                                    const tokenBIsGasToken = isGasOrWrappedGasToken(tokenB, chainId);
                                    tokenA = tokenAIsGasToken ? gasToken : tokenA;
                                    tokenB = tokenBIsGasToken ? gasToken : tokenB;
                                    if (!tokenA || !tokenB) {
                                        return <></>;
                                    }
                                    return item.aprs.map((entry, i) => {
                                        const matchAddress = POPULAR_LP_SELECTION[chainId].filter((selectionItem: any) => {
                                            return tocheckSumAddress(selectionItem.pool_address) === item.address;
                                        });
                                        if (matchAddress.length !== item.aprs.length) {
                                            return <></>;
                                        }
                                        return (
                                            <Stack spacing="20px" key={i}>
                                                <Divider></Divider>
                                                <PopularEntry
                                                    index={i}
                                                    poolIndex={index}
                                                    tokenA={tokenA}
                                                    tokenB={tokenB}
                                                    fee={item.fee}
                                                    leftRange={entry.leftRange}
                                                    rightRange={entry.rightRange}
                                                    apr={entry.apr}
                                                    slideApr={entry.slideApr}
                                                    level={entry.risk}
                                                    setSelect={setSelectIndex}
                                                    setSelectPoolIndex={setSelectPoolIndex}
                                                ></PopularEntry>
                                            </Stack>
                                        );
                                    });
                                })}
                            </Stack>
                        )}
                    </Stack>
                </Card>
            </Stack>
        </VStack>
    );
};

export default AddPopularLiquidity;
